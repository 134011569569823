import React, {useEffect} from 'react'
import {Stack, Main, Layout} from '@layout'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import {Heading, Box} from 'theme-ui'

const styles = {
    h1: {
        textAlign: 'center',
    },
};

const SearchPage = props => {
    useEffect(() => {
        const script = document.createElement("script");

        script.src = `https://cse.google.com/cse.js?cx=8a5d99f48c4026891`;
        script.async = true;

        document.body.appendChild(script);
    }, []);

    return (
        <Layout {...props}>
            <Seo title='Search'/>
            <Divider/>
            <Stack>
                <Main>
                    <Box sx={styles.container}>
                        <Heading variant='h1' as='h1' sx={styles.h1}>
                            Search Results
                        </Heading>

                        <div className="gcse-search"></div>
                    </Box>
                </Main>
            </Stack>
        </Layout>
    )
}

export default SearchPage;